import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { Option } from '../common/form';
import styled from 'styled-components';
import { setActiveCompany } from '../../store';
import useI18n from '../../lib/use_i18n';

const StyledIcon = styled.span`
  padding: 0px;
`;

const styles = {
  control: (styles) => ({
    ...styles,
    borderStyle: 'none',
    backgroundColor: 'none',
  }),
  indicatorSeparator: () => ({}),
  singleValue: (styles) => ({
    ...styles,
    color: 'white',
    fontSize: '16px',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '2px 4px',
    minWidth: '150px',
    maxWidth: '380px',
  }),
  input: (styles) => ({
    ...styles,
    color: 'white',
    'input[type=text]': {
      boxShadow: 'none',
    },
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    padding: '0px',
  }),
  menuList: (styles) => ({
    ...styles,
    color: 'black',
  }),
};

const CaretDownIcon = () => {
  return (
    <StyledIcon className="material-symbols-outlined">
      arrow_drop_down
    </StyledIcon>
  );
};

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <CaretDownIcon />
  </components.DropdownIndicator>
);

const CompaniesSelector = ({ selectedCompany, companies }) => {
  const { translate } = useI18n('top_bar.companies_selector');
  const [selectedOption, setSelectedOption] = useState(() => {
    return { label: selectedCompany.name, value: selectedCompany.id };
  });
  const isInitialMount = useRef(true);

  const options = () => {
    return companies
      .map((company) => ({ label: company.name, value: company.id }))
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      (async () => {
        try {
          const resp = await setActiveCompany(selectedOption.value);
          window.location.replace(resp.headers?.location);
        } catch (e) {
          Flash.alert(e.response.data?.error);
        }
      })();
    }
  }, [selectedOption]);

  return (
    <Select
      id="topBar-dropdown-companies"
      styles={styles}
      noOptionsMessage={() => translate('.no_options')}
      components={{ DropdownIndicator, Option }}
      options={options()}
      defaultValue={selectedOption}
      inputId="topBar-dropdown-companies-input"
      onChange={setSelectedOption}
    />
  );
};

CompaniesSelector.propTypes = {
  selectedCompany: PropTypes.object.isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CompaniesSelector;
