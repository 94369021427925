import 'styles/application';
import '../bundles/react_on_rails';
import { topLevel } from '../bundles/hoc';
import { Notification } from '../bundles/components/common/notification';
import CookieBanner from '../bundles/components/cookie_banner';
import CookieConsent from '../bundles/lib/cookie-consent';
import CompaniesSelector from '../bundles/components/top_bar/companies_selector';

// makes sure Webpack knows images
//eslint-disable-next-line
require.context('../images', true);

// We add the lib to the window object so that it is available to non-webpack
// tracking code.
window.CookieConsent = CookieConsent;

window.ReactOnRails.register({
  Notification: topLevel(Notification),
  CookieBanner: topLevel(CookieBanner),
  CompaniesSelector: topLevel(CompaniesSelector),
});
